<template>

  <b-container>
    <b-row>
      <b-col cols="12">
        <SiteVisit :record ="currentSiteVisit" v-on="handler"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="8" >
          <plotlygraph :propData="plotInfo" divId="plot" @afterplot="plotChanged" v-on:fishSelected="fishSelected"></plotlygraph>
      </b-col>
     </b-row>
     <v-tabs 
     v-if="currentSiteVisit.global_id !== '' && currentSiteVisit.global_id !== undefined"
v-model="tab"
  align-tabs="center"
  color="blue">
  <v-tab value="fish">Fish</v-tab>
  <v-tab value="sample">Samples</v-tab>
</v-tabs>
<v-tabs-items v-model="tab" v-if="currentSiteVisit.global_id !== '' && currentSiteVisit.global_id !== undefined">
  <v-tab-item>
      <div class="dataentry">
        <EditableTable 
        :value="fish" 
        :fields="fishFields" 
        :showAdd="showAdd" 
        tableName="lab_fish" 
        v-on="handler"
        sortField="created_ts"></EditableTable>
      </div>
    </v-tab-item>
    <v-tab-item>
      <div class="dataentry">
          <EditableTable 
         cols="12"
         tableName="lab_fish_sample"
         :value="lab_fish_sample"
         :fields="lab_fish_sample_fields"
         :showAdd="showAdd"
         sortField="created_ts"
         v-on="handler"
          ></EditableTable>
      </div>
    </v-tab-item>
    </v-tabs-items>

 <template>
  <div>
     
    
  </div>
 </template>
 
  </b-container>
</template>

<script>

import EditableTable from '../components/EditableTable.vue'
import plotlygraph from '../components/PlotlyGraph.vue'
import SiteVisit from '../components/SiteVisit.vue'
import support from '../plugins/support'


export default {
  name: "FishLab",
  components:{EditableTable, plotlygraph,SiteVisit},
   
  data() {
    return {
      selectedCell: null,
      selectedPoint: null,
      fishFilter: "",      
     showAdd:true,
      massArray: [],
      lengthArray: [],
      keyArray:[],
      tab:null,

       handler: {
          // onSiteVisitSet: this.siteVisitReset,
          submitSiteVisit: this.submitSiteVisit,
          add_record: this.addTableItem,
      submitItem: this.saveTableItem,
      },
  

  
  plotInfo : { 
        data: [
          {
          x: [],
          y: [],
          name: "Ideal Model", 
           type:'scatter',
            mode:"lines",
            line: {color: "rgb(0,100,80)"}
        },
        {
            x: [],
            y: [],
            fill: "tozerox", 
            fillcolor: "rgba(0,176,246,0.2)", 
            line: {color: "transparent"}, 
            name: "Ideal Model", 
            showlegend: false, 
            type: "scatter"
         },
         {x:[],
          y: [],
          key: [],
          type:'scatter',
          mode:'markers',
          name:'Fish', text:[],
          marker:{color:'rgba(200, 50, 100, .7)',
                        size:16}
          }]
        ,
        layout: {showlegend:false, title:"Fish Length:Mass",
        xaxis:{title:"mass (g)"},yaxis:{title:"length (cm)"},
        width:1000, height:500}
      },

    };
    
  },
  computed: {
    currentSiteVisit() {
          return this.$store.getters['getCurrentRecord']('site_visit');
        },
    //fishFilter: "lab_fish/visit_date/" + this.visit_date + "/station/" + this.station
    currentLabFish() {
      return this.$store.getters['getCurrentRecord']('lab_fish')
    },
    fish() {
      return this.$store.getters['tableData']('lab_fish');
    },
   fishFields(){
    return this.$store.getters['tableFields']('lab_fish')
   },
   lab_fish_sample() {
      return this.$store.getters['tableData']('lab_fish_sample').filter((el)=>
      this.fish.map((f) => f.sin).includes(el.sin ));
    },
   lab_fish_sample_fields(){
    return this.$store.getters['tableFields']('lab_fish_sample')
   },
  },
   created(){
    // this.$watch(
    //       'dataholder',() => {this.checkChanged()
    //       },
    //       {deep: true}
    // );
  },
  async mounted() {

    //fill plot data
    this.fillPlotData();
    support.getApiData('dataTables','lab_fish_sample')
    this.$watch(
          'fish',() => {this.refreshPlot()
          },
          {deep: true}
    );
    
  },
  methods: {

    fillPlotData: function(){
      this.plotInfo.data[2].x = this.fish.map(item=> {return item.weight});
      this.plotInfo.data[2].y = this.fish.map(item=> {return item.length});
      this.plotInfo.data[2].text= this.fish.map(item=> {return `${item.field_species}-${item.created_ts}`});
      this.plotInfo.data[0].x = this.getIdealX();
      this.plotInfo.data[0].y = this.getIdealY();
      this.plotInfo.data[1].x = this.getEnvelopeX();
      this.plotInfo.data[1].y = this.getEnvelopeY();
    },
    //plotting data methods
   
    getIdealX: function(){
      return [1,2,3,4];

    },
    getIdealY: function(){
      return [1,2,3,4];
    },
    
    getEnvelopeX: function(){
      return [1,2,3,4];

    },
    getEnvelopeY: function(){
      return [1,2,3,4];
    }, 
     plotChanged() {
      this.status = 'Plot last updated at ' + new Date();
    },
   
   
    filterFish(station,visit_date){
      //getFishData()
      if ((visit_date !== undefined) && (station !==undefined)) {
      let endpoint ="lab_fish/visit_date/"+ visit_date +"/station/" + station + "/";
      console.log(endpoint);
      //this.getdataholder(endpoint,'labFish');
      this.fishFilter = endpoint //table component responds to change in endpoint
      }
    },
    refreshPlot(){
      console.log("data change detected")
      this.fillPlotData()
    },

      addTableItem(info){
        let sample = {};
        if(info.tableName === 'lab_fish'){
          info.record.parent_id = this.$store.getters['getCurrentRecord']('site_visit')['global_id']
          info.record.station_name = this.$store.getters['getCurrentRecord']('site_visit')['station']
          info.record.visit_date= this.$store.getters['getCurrentRecord']('site_visit')['visit_date']
          if ((info.record.sin !== '')&& (info.record.sin !== undefined)) {
            if (!this.lab_fish_sample.map((s)=> s.sin).includes(info.record.sin)) {
              sample = {sin:info.record.sin}
            }
          }
        }
        support.submit(support.forceFieldTypes(info.tableName,[support.addMandatory(info.tableName,info.record)]), info.tableName)
        .then((result) =>{
          console.log(result)
          support.getApiData('dataTables',info.tableName,`${info.tableName}/visit_date/${this.$store.getters['attribute']('visit_date')}/station/${this.$store.getters['attribute']('station')}`)
          }
        )
        if (sample.sin !== undefined){
          support.submit(support.forceFieldTypes('lab_fish_sample',[support.addMandatory('lab_fish_sample',sample)]), 'lab_fish_sample')
          .then(() => {
            support.getApiData('dataTables','lab_fish_sample')
          })
        }

  },
    saveTableItem(info){
      let sample = {};
      if(info.tableName === 'lab_fish'){
      if ((info.record.sin !== '')&& (info.record.sin !== undefined)) {
            if (!this.lab_fish_sample.map((s)=> s.sin).includes(info.record.sin)) {
              sample = {sin:info.record.sin}
            }
          }
      }
        support.submit(support.forceFieldTypes(info.tableName,[support.addMandatory(info.tableName,info.record)]), info.tableName)
        .then((result) =>{
          console.log(result)
          support.getApiData('dataTables',info.tableName,`${info.tableName}/visit_date/${this.$store.getters['attribute']('visit_date')}/station/${this.$store.getters['attribute']('station')}`)
          }
        )
        if (sample.sin !== undefined){
          support.submit(support.forceFieldTypes('lab_fish_sample',[support.addMandatory('lab_fish_sample',sample)]), 'lab_fish_sample')
          .then(() => {
            support.getApiData('dataTables','lab_fish_sample')
          })
        }

  },
  delete(tableInfo){
    support.deleteRecord(tableInfo)
  },

  
    submitLabFish(tableItem){
      let payload = support.forceFieldTypes('lab_fish',[support.addMandatory('lab_fish',tableItem)])
      support.submit(payload,'lab_fish');
    } ,
    async submitSiteVisit(submitInfo){
      await this.submit(submitInfo)
      .then(()=>
        { console.log("success submitting data, reloading visit")
        this.$store.commit('setSiteVisit')
        })
      .catch(
        console.log("error submitting data")
        )

    },
    //fish selected on plot
    fishSelected(fishPosition){
      console.log(fishPosition)
      this.$store.commit('updateAttribute',{attribute:'current_lab_fish',value:this.fish[fishPosition]})
      //set current fish to selected dot
      //set current fish to selected=true

    },
    // refreshTable(){
    //   this.filterFish(this.station, this.visit_date);
    // },
    
   
}
    
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .dataentry {
    display: flex;
    overflow-x: auto;
} 
footer {
  font-size: 70%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>